<template>
  <div class="operationRecord clearfix">
    <div class="operationRecord-operate">
      <span style="margin-right:10px">状态</span>
      <el-select @change="getOrderRecordList" @clear="getOrderRecordList" v-model="status" clearable placeholder="请选择">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <span style="margin-left:20px">企业名称</span>
      <el-input style="width:267px;margin: 0 10px;" v-model="clientName" placeholder="请输入内容"></el-input>
      <el-button @click="getOrderRecordList" type="primary">确定</el-button>
    </div>
    <div v-if="tableData.length">
      <div class="operationRecord-table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column align="center" label="序号">
            <template slot-scope="scope">
              {{ (pageNum - 1) * 5 + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              {{ scope.row.status | orderRecordStatus }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="clientName" label="企业名称"> </el-table-column>
          <el-table-column align="center" prop="dealUser" label="处理人"> </el-table-column>
          <el-table-column align="center" prop="dealTime" label="处理时间"> </el-table-column>
          <el-table-column align="center" prop="d" label="文件">
            <template slot-scope="scope">
              <span v-if="scope.row.fileUrl" @click="showDocuments(scope.row)" style="color:#409eff;cursor: pointer;">查看文件</span>
              <span v-else>无</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination class="fr" @current-change="pageChange" :page-size="pageSize" :background="true" layout="prev, pager, next ,jumper" :total="total" />
    </div>
    <div v-else style="height: 300px;display: flex;align-items: center;justify-content: center;">
      <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <img src="@/assets/empty.png" />
        <span style="margin-top:20px">暂无搜索内容请重新搜索</span>
      </div>
    </div>
    <!-- 查看文件弹窗 -->
    <dialog-browse-documents ref="documents" />
  </div>
</template>
<script>
import {getOrderRecordList} from '../../api.js';
import {formatDialogFile} from '@/modules/mixin/order-mixin.js';
import dialogBrowseDocuments from './operate/dialog-browse-documents';
export default {
  name: 'OperationRecord',
  props: {
    detailInfo: {
      type: Object
    }
  },
  components: {
    dialogBrowseDocuments
  },
  mixins: [formatDialogFile],
  data() {
    return {
      info: {},
      clientName: '',
      options: [
        {
          value: 'NEW',
          label: '创建订单'
        },
        {
          value: 'FH',
          label: '发货'
        },
        {
          value: 'SH',
          label: '签收'
        },
        {
          value: 'KP',
          label: '开发票'
        },
        {
          value: 'ZF',
          label: '支付(商票)'
        },
        {
          value: 'SK',
          label: '收款'
        },
        {
          value: 'QX',
          label: '取消订单'
        },
        {
          value: 'QXS',
          label: '取消成功'
        },
        {
          value: 'QXR',
          label: '拒绝取消'
        },
        {
          value: 'YQS',
          label: '合同签署'
        }
        // {
        //   value: 'XQ',
        //   label: '详情'
        // }
      ],
      PaginationData: [],
      status: '',
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 5
    };
  },
  filters: {
    orderRecordStatus(value) {
      switch (value) {
        case 'NEW':
          return '创建订单';
          break;
        case 'FH':
          return '发货';
          break;
        case 'SH':
          return '签收';
          break;
        case 'KP':
          return '开发票';
          break;
        case 'ZF':
          return '支付(商票)';
          break;
        case 'SK':
          return '收款';
          break;
        case 'QX':
          return '取消订单';
          break;
        case 'QXS':
          return '取消成功';
          break;
        case 'QXR':
          return '拒绝取消';
          break;
        case 'YQS':
          return '合同签署';
          break;
        case 'XQ':
          return '详情';
          break;
      }
    }
  },
  created() {
    // 因为有一个mixin引入的查看文件的弹窗，所以要用这行代码做兼容
    this.info = this.detailInfo;
    this.getOrderRecordList();
  },
  computed: {
    getOrderType() {
      let myStr = '';
      switch (this.$route.query.orderType) {
        case 'TS':
          myStr = '1';
          break;
        case 'AU':
          myStr = '2';
          break;
        case 'RE':
          myStr = '3';
          break;
        case 'BI':
          myStr = '4';
          break;
      }
      return myStr;
    }
  },
  methods: {
    resetData() {},
    getfillingData() {},
    showDocuments(data) {
      this.$refs.documents.dialogVisible = true;
      this.$refs.documents.imgData = this.formatFile(data).arr;
      this.$refs.documents.total = this.formatFile(data).total;
    },
    pageChange(val) {
      this.pageNum = val;
      this.getOrderRecordList();
    },
    async getOrderRecordList() {
      let params = {
        orderId: this.$route.query.orderid,
        orderType: this.getOrderType,
        status: this.status,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        clientName: this.clientName
      };
      let res = await getOrderRecordList(params);
      if (res.errno === 0) {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.operationRecord {
  padding: 40px 50px;
  background-color: #fff;
  &-operate {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 35px;
  }
  &-table {
    margin-bottom: 50px;
  }
}
</style>
